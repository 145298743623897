import styled from "styled-components";
import settingsStyled from "../styled/settings.styled";

const SubscribeUsStyled = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 70px 20px;
  background: #f9fbff;
  @media ${settingsStyled.m479} {
    padding: 31px 70px 70px;
  }
  .subscribeUs {
    &-form {
      position: relative;

      &-email {
        .ant-form-item-control-input-content {
          @media ${settingsStyled.m767} {
            display: flex;
            justify-content: center;
          }
        }
        input {
          position: relative;
          box-shadow: 0 8px 25px 0 rgba(13, 10, 44, 0.06);
          border: solid 1px #eff0f6;
          width: 629px;
          height: 88px;
          border-radius: 22px;

          @media ${settingsStyled.m767} {
            width: 83.59vw;
          }
        }
      }
      &-button {
        position: absolute;
        top: 20px;
        right: 17px;
        width: 145px;
        height: 55px !important;
        @media ${settingsStyled.m767} {
          right: 55px;
        }
        @media ${settingsStyled.m719} {
          right: 25px;
        }
        @media ${settingsStyled.m479} {
          top: 30px;
          right: -35px;
        }
        .ant-blueBtn {
          @media ${settingsStyled.m479} {
            min-height: 40px !important;
          }
          min-height: 55px !important;

          span {
            overflow: visible;
          }
        }
        button {
          width: 145px;
          height: 20px;
          flex-grow: 0;
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.11;
          letter-spacing: normal;
          text-align: center;
          color: #fff;
          border-radius: 18px;

          @media ${settingsStyled.m767} {
            width: 18.893vw;
            height: 8.351vw;
            border-radius: 8px;
            padding: 0 2px;
          }
          span {
            @media ${settingsStyled.m767} {
              font-size: 3vw;
            }
          }
        }
      }
      &-checkbox {
        width: 600px;
        @media ${settingsStyled.m767} {
          width: 100%;
        }
        span {
          span {
            height: 77px;
            font-size: 16px;
            line-height: 1.63;
            line-height: 26px;
            color: #5d7285;

            @media ${settingsStyled.m479} {
              font-size: 3.296vw;
              line-height: normal;
            }
            span {
              color: #22325e !important;
              font-weight: bold;
            }
          }
        }
      }
    }

    &__subTitle {
      width: 50%;
      margin: auto;
      margin-bottom: 43px;

      @media ${settingsStyled.m1200} {
        width: 80%;
      }

      @media ${settingsStyled.m479} {
        width: 100%;
      }
    }
  }

  .subscribeUs-form-email
    .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    border-radius: 18px; /* or border-radius: inherit; */
  }

  .subscribeUs-form-checkbox {
    .ant-checkbox-wrapper .ant-checkbox-inner {
      margin-top: 10px;
      background-color: #365097 !important;
    }
    .ant-checkbox-wrapper {
      align-items: flex-start !important;
    }
    .ant-checkbox-wrapper .ant-checkbox-inner::after {
      border-color: #fff;
    }
    P {
      color: #5d7285;
      font-size: 16px;
      line-height: 26px;
      max-width: 591px;
      padding-right: 35px;

      strong {
        /* Ersey A.S. yazisi */
        color: #22325e;
      }
    }
  }
`;

export { SubscribeUsStyled };
