import { Form, Input, Checkbox, Button, Alert } from "antd";
import { SubscribeUsStyled } from "./subscribeUs.styled";
import { useCallback, useRef } from "react";
import Service from "../service";
import notificationMessage from "../shared/partnerComponents/messages/notificationMessage";
import FormElement from "../shared/partnerComponents/formElement";
import FormCustom from "../shared/partnerComponents/formElement/formCustom";
import getWordControl from "../shared/utils/language/getWordControl";

const SubscribeUS = ({ title, subTitle }) => {
  const service = new Service();
  const [form] = Form.useForm();
  const { GetWord } = getWordControl();
  const onFinish = useCallback((values) => {
    const { email, remember } = values;
    if (email && remember) {
      service.membership
        .SetUpdateNewsletterEmail({
          data: {
            email: email,
            UserAgreementAccepted: remember,
          },
        })
        .then((response) => {
          if (!response.hasFailed) {
            notificationMessage("success", GetWord("success"));
            form.resetFields();
          } else {
            return notificationMessage(
              "error",
              GetWord(response?.messages?.[0]?.description)
            );
          }
        });
    }
  }, []);

  return (
    <SubscribeUsStyled className="subscribeUs">
      <div className="subscribeUs__title newTitle">{title}</div>
      <div className="subscribeUs__subTitle newSubTitle">{subTitle}</div>
      <div className="subscribeUs-form">
        <FormCustom
          Form={Form}
          formSettings={form}
          onFinish={onFinish}
          className="followUs"
        >
          <div className="subscribeUs-form-emailSubmit">
            <FormElement
              className="subscribeUs-form-email"
              formElementName="textInput"
              propName="email"
              placeholder="EnterEmailAddress"
              requiredStatus={true}
            />
            <FormElement
              className="subscribeUs-form-button"
              formElementName="button"
              btnColor="blueBtn"
              label="subscribeButtonLabel"
            />
          </div>
          <div className="subscribeUs-form-checkbox">
            <FormElement
              className=""
              formElementName="checkboxSingle"
              propName="remember"
              contractActive={false}
              label={"subscribeUsCheckBoxLabel"}
              requiredStatus={true}
              secondLabel={"Ersey Makina, "}
            />
          </div>
        </FormCustom>
      </div>
    </SubscribeUsStyled>
  );
};

export default SubscribeUS;
